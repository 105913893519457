import * as React from "react"
import Layout from "../components/layout"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Seo from "../components/seo"
import Events from "../components/events"
import Principles from "../components/principles"
import Testimonials from "../components/testimonials"
import { Container } from "../components/ui/grid"
import Button from "../components/ui/button"
import BgElement from "../components/ui/bgElement"
import BgCircle from "../components/ui/bgCircle"
import LogoIcon from "../svg/icon.svg"
import thumbnail from "../images/thumbnail-default.png"

const IndexPage = ({ location }) => {
  return (
    <Layout location={location} decor={false}>
      {/*<Seo
        title="Home"
        description="Clusterdiali is a set of open-source standards and best practices."
        url={location.href}
        image={thumbnail}
      />*/}

      <section className="pt-28 md:pt-48 2xl:pt-56 overflow-hidden">
        <Container>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <h1 className="mb-6">
                What is <span className="text-accent">ClusterDiali</span>?
              </h1>
              <h1 className="mb-6">
              <p className="text-xl md:text-2xl xl:text-3xl">
                <span className="text-accent text-5xl">ClusterDiali</span> is a simple way to aquire the baseline{" "}
                <span className="text-accent">DevOps</span> capabilities in {" "} <span className="text-accent">1 day</span>
              </p>
              </h1>
              <h1 className="mb-6">
                <p className="text-xl md:text-2xl xl:text-3xl">
                  Based on <span className="text-accent">k8s</span> , best of bread <span className="text-accent">OSS</span> and <span className="text-accent">Gitops principals</span> , <span className="text-accent text-5xl">ClusterDiali</span> offer you a good starting point for your product journey
                </p>
              </h1>


              <div className="mt-4">
                <Button
                  to="/get-involved"
                  label="Contact Us"
                  className="w-full mt-8 mb-8 md:w-auto"
                />
              </div>
            </div>

            <div className="w-full lg:w-1/2">
              <div className="relative flex justify-center items-center mt-10 h-full md:mt-16 lg:mt-0">
                <BgCircle />
                <LogoIcon className="relative w-5/6 h-auto m-auto text-[#5a90f3] xl:left-12 lg:w-4/5" />
              </div>
            </div>
          </div>

          <div className="pt-12 md:pt-32">
            <Testimonials />
          </div>
        </Container>
      </section>

      <section id="principles" className="relative pt-24  md:pt-32 xl:pt-40">
        <BgElement className="top-12 lg:top-64" />

        <Container>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/5">
              <h2 className="mb-12 pr-64 text-5xl md:text-6xl lg:sticky lg:top-12 xl:text-7xl lg:mb-0">
                <span className="text-accent">ClusterDiali</span>{" "}
                <span className="font-normal">Principles</span>{" "}
              </h2>
            </div>

            <div className="w-full lg:w-3/5">
              <Principles />
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
